<template name="component-name">
  <div class="orders">
    <p class="text-center mt-5" v-if="gettingCoupons">
      <b-spinner label="Loading..."></b-spinner>
    </p>
    <div class="p-5" v-else>
      <b-nav-form>
        <b-form-input
          class="mr-sm-2"
          placeholder="Filter"
          v-model="filter"
        ></b-form-input>
        <b-button
          size="sm"
          class="my-2 my-sm-0"
          v-if="filter"
          @click="filter = ''"
          >Clear</b-button
        >
      </b-nav-form>
      <b-table
        show-empty
        small
        stacked="md"
        :items="coupons"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        class="mt-3"
      >
        <template v-slot:cell(DeliveryDate)="row">
          {{ row.value | onlyDate }}
        </template>

        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            @click="handleClick(row.item)"
            class="mr-1"
            variant="danger"
          >
            Details
          </b-button>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-pagination"
      ></b-pagination>

      <hr />
    </div>

    <b-modal title="Details" v-model="detailsModal" centered="" hideFooter>
      <div class="pl-2 pr-2">
        <table class="car-options mt-2">
          <tr>
            <th>VIN</th>
            <td>:</td>
            <td>{{ clickedCoupon.VIN }}</td>
          </tr>
          <tr>
            <th>Dealer</th>
            <td>:</td>
            <td>{{ clickedCoupon.DealerName }}</td>
          </tr>
          <tr>
            <th>Owner Name</th>
            <td>:</td>
            <td>{{ clickedCoupon.OwnersName }}</td>
          </tr>
          <tr>
            <th>OwnerAddress</th>
            <td>:</td>
            <td>{{ clickedCoupon.OwnersAddress }}</td>
          </tr>

          <tr>
            <th>Entry Date</th>
            <td>:</td>
            <td>{{ clickedCoupon.EntryDate | onlyDate }}</td>
          </tr>

          <tr>
            <th>Model</th>
            <td>:</td>
            <td>{{ clickedCoupon.Model }}</td>
          </tr>

          <tr>
            <th>Phone</th>
            <td>:</td>
            <td>{{ clickedCoupon.PhoneNumber }}</td>
          </tr>

          <tr>
            <th>Email Address</th>
            <td>:</td>
            <td>{{ clickedCoupon.EmailAddress }}</td>
          </tr>
        </table>

        <p class="text-center">
          <img src="/img/car-thumbnail.png" alt="Image" />
        </p>

        <div class="text-right">
          <b-button variant="danger" @click="detailsModal = false"
            >Approve</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DashboardService from "../service/dashboard";
import { setPrivateHaders } from "../service/axiosInstance";
export default {
  data() {
    return {
      fields: [
        {
          key: "VIN",
          label: "VIN",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DealerName",
          label: "Dealer",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "Model",
          label: "Model",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "OwnersName",
          label: "Owner Name",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DeliveryDate",
          label: "Delivery Date",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Actions" }
      ],
      coupons: [],
      gettingCoupons: true,
      clickedCoupon: {},
      detailsModal: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {},
  mounted() {
    setPrivateHaders(
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJEZWFsZXJVc2VySUQiOjEwMjAsIkZ1bGxOYW1lIjoiS2VqaSBPbHVidWFkZSIsIkVtYWlsQWRkcmVzcyI6Im9sdWJ1YWRlQHJ0YnJpc2NvZS5jb20iLCJQYXJ0c09yTVYiOjAsIkFsbG93VG9PcmRlciI6MSwiUGhvbmVOdW1iZXIiOiIiLCJEZWFsZXJMb2NhdGlvbklEIjoxNCwiaXNBZG1pbiI6MSwiRGVhbGVySUQiOjIsIkRlYWxlck5hbWUiOiJSVCBCcmlzY29lIE5pZ2VyaWEiLCJBZG1pbkVtYWlsQWRkcmVzcyI6Ik9sdWJ1YWRlQHJ0YnJpc2NvZS5jb20iLCJMb2NhdGlvbkNvZGUiOiIxMzAiLCJMb2NhdGlvbk5hbWUiOiJNYXRvcmkgLUJyYW5jaCIsIlBhcnRzVmVuZG9yTm8iOiJURFAwMjAxMCIsIlZlaGljbGVWZW5kb3JObyI6IlREVjAyMDIwIiwiTG9nb1BhdGgiOiIuL2Fzc2V0cy9pbWcvYnJpc2NvZV9sb2dvLmpwZyIsImlhdCI6MTU4NDU5NDAzNiwiZXhwIjoxNTg2MzIyMDM2fQ.4im77un7vwLaSrtQ-3s17jElubfhfjtOLOBFe9gSxQE"
    );
    this.getAllCoupons();
  },
  methods: {
    getAllCoupons() {
      this.gettingCoupons = true;
      DashboardService.getAllCoupons()
        .then(res => {
          this.coupons = res.data.coupons;
          this.totalRows = this.coupons.length;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.gettingCoupons = false;
        });
    },
    handleClick(coupon) {
      this.clickedCoupon = { ...coupon };
      this.detailsModal = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
.coupon-table tr {
  cursor: pointer;
}

.my-pagination {
  max-width: 400px;
}
</style>
